<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="alarmDevice"></gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search keyWord="设备编号" width="300px" :search.sync="form.deviceNo"></gc-custom-search>
				<gc-custom-search
					v-if="tenantType == 1"
					keyWord="客户类型"
					width="300px"
					type="select"
					:search.sync="form.userType"
					:searchOption="options.userType"
					:needAllForSearch="true"
					:searchOptionKey="{
						label: 'name',
						value: 'defaultValue',
					}"
				></gc-custom-search>
				<gc-custom-search
					v-if="tenantType != 1"
					keyWord="设备类别"
					width="300px"
					type="select"
					filterable
					:search.sync="form.firstCategory"
					:searchOption="options.firstCategory"
					:searchOptionKey="{
						label: 'name',
						value: 'productCategoryId',
					}"
					:needAllForSearch="true"
				></gc-custom-search>
				<gc-custom-search
					v-if="tenantType != 1"
					keyWord="设备类型"
					width="300px"
					type="select"
					filterable
					:search.sync="form.deviceTypeId"
					:searchOption="options.deviceTypeId"
					:needAllForSearch="true"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button type="primary" @click="getList(1, apiGetAlarmDeviceList)" v-click-blur>查 询</el-button>
					<el-button @click="reset(apiGetAlarmDeviceList)" v-click-blur>重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-alarm-device-${tenantType}`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					:columns="columnList"
					:table-data="tableData"
					:border="true"
					:showPage="true"
					:total="page.total"
					:page-size="page.size"
					:currentPage="page.current"
					@current-page-change="
						currentPage => {
							currentPageChange(currentPage, apiGetAlarmDeviceList)
						}
					"
					@size-change="
						size => {
							page.size = size
							currentPageChange(1, apiGetAlarmDeviceList)
						}
					"
				></gc-table>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { apiGetAlarmDeviceList } from '@/apis/alarm.api.js'
import common from '../mixins/common.js'
import deviceTypeOption from '../mixins/deviceTypeOption.js'

export default {
	name: 'alarmDevice',
	mixins: [common, deviceTypeOption],
	data() {
		return {
			form: {
				deviceNo: null, //设备编号
				firstCategory: null, // 设备类别
				deviceTypeId: null, //设备类型
				userType: null, //客户类型
			}, //筛选条件
			cancelRequest: true,
			columns: [],
		}
	},
	computed: {
		tenantType() {
			return this.$store.getters.userInfo.tenantType || '0'
		},

		getDeviceTypeFlag() {
			return this.tenantType == 1
		},

		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},
	mounted() {
		if (this.$route.params?.firstCategory) {
			this.form.firstCategory = this.$route.params.firstCategory
		}
		if (this.tenantType != 1) {
			this.getFirstCategory().then(data => {
				this.$set(this.options, 'firstCategory', data)
			})
		}
		this.$set(this.options, 'userType', this.$store.getters.dataList.userType || [])
		// 默认请求第一页数据
		this.getList(1, apiGetAlarmDeviceList)
	},
	methods: {
		apiGetAlarmDeviceList,
		...mapActions({
			getFirstCategory: 'common/getFirstCategory',
			getDeviceTypes: 'common/getAllDeviceTypeList',
		}),
		getDeviceTypeOptions() {
			this.getDeviceTypes({
				firstCategory: this.form.firstCategory || '',
			}).then(data => {
				this.$set(this.options, 'deviceTypeId', data)
			})
		},
	},

	watch: {
		'form.firstCategory': {
			handler() {
				this.form.deviceTypeId = null
				this.getDeviceTypeOptions()
			},
		},

		tenantType: {
			handler(newVal) {
				this.columns = [
					{
						key: 'deviceTypeName',
						name: '设备类型',
					},
					{
						key: 'deviceNo',
						name: '设备编号',
					},
					{
						key: 'alarmCount',
						name: '告警数量',
					},
					{
						key: 'oprate',
						name: '操作',
						render: (h, row) => {
							//  自定义表格内容展示
							return this.$has('monitor:device:account:record:alarm')
								? h(
										'el-button',
										{
											props: {
												type: 'text',
												size: 'medium',
											},
											on: {
												click: () => {
													this.$router.push({
														name: 'deviceDetail',
														query: {
															deviceId: row.deviceId,
															deviceType: row.deviceTypeId,
														},
														params: {
															name: 'AlarmRecord',
														},
													})
												},
											},
										},
										'查看详情',
								  )
								: h('div', {}, '/')
						},
					},
				]
				if (newVal === '1') {
					this.columns.splice(0, 1, {
						key: 'userTypeName',
						name: '客户类型',
					})
				}
			},
			immediate: true,
		},
	},

	activated() {
		if (this.$route.params?.update) {
			this.form = this.$options.data().form
			this.form.firstCategory = this.$route.params.firstCategory
			this.getList(1, apiGetAlarmDeviceList)
		}
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
</style>
